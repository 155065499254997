import {
  IBoxBannerContent,
  IBoxBannerContentSettings,
  IBoxBannerDisplaySettings,
} from "../../../boxes/BoxBanner/BoxBanner.types";
import LinkWrapper from "../../../global/components/LinkWrapper/LinkWrapper";
import StyledInnerBox from "../../../settingsPatterns/elements/StyledInnerBox/StyledInnerBox";
import StyledButton from "../../styleElements/StyledButton/StyledButton";
import StyledText from "../../styleElements/StyledText/StyledText";
import {
  IBoxSliderDisplaySettings,
  ISlideContent,
  ISlideContentSettings,
} from "../../../boxes/BoxSlider/BoxSlider.types";

interface InnerBoxProps {
  content: ISlideContent | IBoxBannerContent;
  settings:
    | (ISlideContentSettings & IBoxSliderDisplaySettings)
    | (IBoxBannerContentSettings & IBoxBannerDisplaySettings);
  messages: {
    box_content_title: string;
    box_content_description: string;
    box_content_main_button_heading: string;
    box_content_secondary_button_heading: string;
  };
  className?: string;
}

const InnerBox: React.FC<InnerBoxProps> = ({
  content,
  settings,
  className,
  messages,
}) => {
  return (
    <StyledInnerBox
      $boxSettings={settings.box}
      $margin={settings.padding} // business rule: we use Box (Slider/Banner) padding as margin in InnerBox
      className={className ? className : "inner-box"}
    >
      <StyledText
        $settings={settings.title}
        className={className ? className + "__title" : "inner-box__title"}
      >
        {messages["box_content_title"]}
      </StyledText>

      <StyledText
        $settings={settings.description}
        className={
          className ? className + "__description" : "inner-box__description"
        }
      >
        {messages["box_content_description"]}
      </StyledText>
      <div className="inner-box__button-container">
        {settings.mainButton.show && (
          <div
            className={
              className ? className + "__main-button" : "inner-box__main-button"
            }
          >
            <LinkWrapper href={content.mainButton?.link ?? ""}>
              <StyledButton $settings={settings.mainButton} renderAs="button">
                {messages["box_content_main_button_heading"]}
              </StyledButton>
            </LinkWrapper>
          </div>
        )}
        {settings.secondaryButton.show && (
          <div
            className={
              className
                ? className + "__secondary-button"
                : "inner-box__secondary-button"
            }
          >
            <LinkWrapper href={content.secondaryButton?.link ?? ""}>
              <StyledButton
                $settings={settings.secondaryButton}
                renderAs="button"
              >
                {messages["box_content_secondary_button_heading"]}
              </StyledButton>
            </LinkWrapper>
          </div>
        )}
      </div>
    </StyledInnerBox>
  );
};

export default InnerBox;
